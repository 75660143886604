<template>
  <div id="stepper">
    <div>
      <div class="text-left d-inline-block" style="width: 16%">
        <p class="mb-0 font-weight-medium" :style="statusColor(0)" style="font-size: 12px">Stand-by Cliente</p>
      </div>
      <div class="d-inline-block" style="width: 20%">
        <p class="mb-0 text-center font-weight-medium" :style="statusColor(1)" style="font-size: 12px">Implementación</p>
      </div>
      <div class="d-inline-block" style="width: 28%">
        <p class="mb-0 text-center font-weight-medium" :style="statusColor(2)" style="font-size: 12px">Preparación</p>
        <p class="mb-0 text-center font-weight-medium" :style="statusColor(2)" style="font-size: 12px">(Próximo mes)</p>
      </div>
      <div class="d-inline-block" style="width: 20%">
        <p class="mb-0 text-center font-weight-medium" :style="statusColor(3)" style="font-size: 12px">Go-Live</p>
      </div>
      <div class="text-right d-inline-block" style="width: 16%">
        <p class="mb-0 font-weight-medium" :style="statusColor(4)" style="font-size: 12px">AC Actualizado</p>
      </div>
    </div>
    <v-stepper>
      <v-stepper-header>
        <v-stepper-step step="." color="#2222BF" :complete="checkCompleteStatus(0)"></v-stepper-step>
        <v-divider :style="checkCompleteStatus(1) ? 'border: 1px solid #2222BF;' : 'border: 1px solid #DFDFE0;'"></v-divider>
        <v-stepper-step step="." color="#2222BF" :complete="checkCompleteStatus(1)"></v-stepper-step>
        <v-divider :style="checkCompleteStatus(2) ? 'border: 1px solid #2222BF;' : 'border: 1px solid #DFDFE0;'"></v-divider>
        <v-stepper-step step="." color="#2222BF" :complete="checkCompleteStatus(2)"></v-stepper-step>
        <v-divider :style="checkCompleteStatus(3) ? 'border: 1px solid #2222BF;' : 'border: 1px solid #DFDFE0;'"></v-divider>
        <v-stepper-step step="." color="#2222BF" :complete="checkCompleteStatus(3)"></v-stepper-step>
        <v-divider :style="checkCompleteStatus(4) ? 'border: 1px solid #2222BF;' : 'border: 1px solid #DFDFE0;'"></v-divider>
        <v-stepper-step step="." color="#2222BF" :complete="checkCompleteStatus(4)"></v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <div class="text-center">
      <div class="d-inline-block" style="width: 16%">
        <p class="mb-0 text-left" style="font-size: 12px; color: #757575" v-if="stage==0">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </div>
      <div class="d-inline-block" style="width: 20%">
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==1">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </div>
      <div class="d-inline-block" style="width: 28%">
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==2">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </div>
      <div class="d-inline-block" style="width: 20%">
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==3">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </div>
      <div class="d-inline-block" style="width: 16%">
        <p class="mb-0 text-right" style="font-size: 12px; color: #757575" v-if="stage==4">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['stage_date','stage_name'],
    data: () => ({
      stage: 0
    }),
    methods:{
      checkCompleteStatus(index){
        if(index <= this.stage){
          return true
        }
        return false
      },
      stepperColor(index){
        if(index <= this.stage){
          return '#E90019'
        }
        return '#2222BF'
      },
      statusColor(index){
        if(index <= this.stage){
          return "color: #2222BF"
        }
        return "color: #A6A6A6"
      },
      setStageIndex(){
        if(this.stage_name == "Stand by Cliente"){
          this.stage = 0
        }
        if(this.stage_name == "Implementacion"){
          this.stage = 1
        }
        if(this.stage_name == "Preparacion (proximo mes)"){
          this.stage = 2
        }
        if(this.stage_name == "Go-Live"){
          this.stage = 3
        }
        if(this.stage_name == 'AC Actualizado'){
          this.stage = 4
        }
      }
    },
    mounted(){
      this.setStageIndex()
    }
  }
</script>

<style lang="scss">
#stepper {
  .v-stepper{
    box-shadow: none;

    .v-stepper__header{
      height: 50px;

      .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step{
        background: #757575!important
      }

      .v-stepper__step{
        padding: 0px!important;

        .v-stepper__step__step{
          border: 2px solid white!important;
          box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
          margin: 0!important;
          color: transparent;

          .v-icon{
            display: none
          }
        }
      }
    }
  }
}
</style>
