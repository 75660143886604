<template>
  <div>
    <v-card class="blue_line px-5 pb-2 pt-3" flat>
      <v-row no-gutters>
        <v-col cols="12" md="6" lg="4">
          <div class="d-flex ml-0">
            <v-icon class="mb-6" color="#2222BF">mdi-file-pdf-outline</v-icon>
            <div class="ml-2">
              <a class="mb-0 mt-1 text-decoration-underline" style="color: #2222BF; font-size: 13px" :href="`${entorno}/es/remuneraciones/avanzados/editar-acuerdo-comercial/${id}`" target="_blank">Acuerdo comercial N° {{id}}</a>
              <p class="mb-0" style="color: #757575; font-size: 13px">Vigente desde el {{date | moment('DD/MM/YYYY')}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="2" align-self="center">
          <p class="font-weight-bold my-2 my-md-0 ml-8 ml-md-11 ml-lg-0 text-lg-center" style="font-size: 20px">{{info.currency}} {{info.value}}</p>
        </v-col>
        <v-col cols="12" md="6" lg="3" align-self="center">
          <div class="ml-8 ml-lg-10">
            <p class="mb-0 font-weight-bold" style="color: #00AF7D; font-size: 13px">Ganado</p>
            <p class="mb-2 mb-md-0" style="color: #757575; font-size: 13px">{{info.won_on | moment('DD MMMM YYYY')}}</p>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3" align-self="center">
          <div class="d-flex negative_margin my-1 my-md-0">
            <v-avatar size="38" class="mr-2" :color="info.sales_manager.personadetalle_set.length>0 && info.sales_manager.personadetalle_set[0].foto ? '' : '#2222BF'">
              <img :src="info.sales_manager.personadetalle_set[0].foto" :alt="info.sales_manager.nombre" v-if="info.sales_manager.personadetalle_set.length>0 && info.sales_manager.personadetalle_set[0].foto">
              <span class="white--text text-uppercase" v-else>{{info.sales_manager.nombre[0] || ''}}{{info.sales_manager.apellidoPaterno[0] || ''}}</span>
            </v-avatar>
            <div>
              <p class="mb-0" style="color: #757575; font-size: 13px">Propietario</p>
              <p class="mb-0 font-weight-medium" style="font-size: 13px">{{info.sales_manager.nombre || '-'}} {{info.sales_manager.apellidoPaterno}} {{info.sales_manager.apellidoMaterno}}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
let WEB_HOST = window.location.host.includes('localhost') ? 'http://localhost:9000' : 'https://' + window.location.host.substring(8)

export default {
  props: ['info','date','id'],
  data: () => ({
    entorno: WEB_HOST,
  }),
}
</script>

<style lang="scss">
.blue_line{
  border-left: 6px solid #2222BF!important;
}

.negative_margin{
  margin-left: -14px
}

@media (min-width: 960px){
  .negative_margin{
    margin-left: 0
  }
}
</style>
