<template>
  <v-main>
    <div v-if="deals">
      <div v-for="(deal,i) in deals" :key="i" class="mb-10">
        <v-row v-for="(d,i) in deal.organizations" :key="i">
          <v-col cols="12" lg="3">
            <commercial-info :info="d"></commercial-info>
          </v-col>
          <v-col cols="12" lg="9">
            <agreement-details :info="d.deals[0]" :date="deal.since" :id="deal.id"></agreement-details>
            <service-monitoring v-for="(service,i) in d.deals" :key="i" :info="service"></service-monitoring>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CommercialInfo from '../components/Commercial/CommercialInfo'
import AgreementDetails from '../components/Commercial/AgreementDetails'
import ServiceMonitoring from '../components/Commercial/ServiceMonitoring'

export default {
  components: {
    CommercialInfo,
    AgreementDetails,
    ServiceMonitoring
  },
  methods: mapActions(['getCommercialDeals']),
  computed: mapState(['deals']),
  created(){
    this.getCommercialDeals()
  }
}
</script>
