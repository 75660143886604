<template>
  <div>
    <v-card flat>
      <div class="pa-5" style="background: #2222BF;">
        <p class="white--text mb-2 font-weight-bold mt-2">{{info.name}}</p>
        <p class="white--text mb-0" style="font-size: 13px">RUT: {{info.rut}}</p>
      </div>
      <div class="pa-5">
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="12">
            <p style="font-size: 12px; color: #757575;" class="mb-2">Dirección</p>
            <p style="font-size: 13px" class="mb-5">{{info.address}}</p>
          </v-col>
          <v-col cols="12" md="6" lg="12">
            <p style="font-size: 12px; color: #757575;" class="mb-2">Encargado de facturación</p>
            <p style="font-size: 13px" class="mb-1 font-weight-bold">{{info.billing_person}}</p>
            <p style="font-size: 13px; color: #2222BF" class="mb-1 text-decoration-underline">{{info.billing_email}}</p>
            <p style="font-size: 13px" class="mb-5">Tel: {{info.billing_phone}}</p>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['info']
}
</script>
