<template>
  <div id="stepper_vertical">
    <v-divider class="mt-3"></v-divider>
    <v-stepper vertical v-model="stage">
      <v-stepper-step step="0" color="#2222BF" :complete="checkCompleteStatus(0)">
        <p class="font-weight-medium" :class="stage==0 ? 'mb-1' : 'mb-0'" :style="statusColor(0)" style="font-size: 12px">Stand-by Cliente</p>
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==0">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </v-stepper-step>
      <v-stepper-content step="0" :style="stage>=1 ? 'border-left-color: #2222BF;' : ''"></v-stepper-content>
      <v-stepper-step step="1" color="#2222BF" :complete="checkCompleteStatus(1)">
        <p class="text-center font-weight-medium" :class="stage==1 ? 'mb-1' : 'mb-0'" :style="statusColor(1)" style="font-size: 12px">Implementación</p>
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==1">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </v-stepper-step>
      <v-stepper-content step="1" :style="stage>=2 ? 'border-left-color: #2222BF;' : ''"></v-stepper-content>
      <v-stepper-step step="2" color="#2222BF" :complete="checkCompleteStatus(2)">
        <p class="text-center font-weight-medium" :class="stage==2 ? 'mb-1' : 'mb-0'" :style="statusColor(2)" style="font-size: 12px">Preparación (Próximo mes)</p>
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==2">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </v-stepper-step>
      <v-stepper-content step="2" :style="stage>=3 ? 'border-left-color: #2222BF;' : ''"></v-stepper-content>
      <v-stepper-step step="3" color="#2222BF" :complete="checkCompleteStatus(3)">
        <p class="text-center font-weight-medium" :class="stage==3 ? 'mb-1' : 'mb-0'" :style="statusColor(3)" style="font-size: 12px">Go-Live</p>
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==3">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </v-stepper-step>
      <v-stepper-content step="3" :style="stage>=4 ? 'border-left-color: #2222BF;' : ''"></v-stepper-content>
      <v-stepper-step step="4" color="#2222BF" :complete="checkCompleteStatus(4)">
        <p class="font-weight-medium" :class="stage==4 ? 'mb-1' : 'mb-0'" :style="statusColor(4)" style="font-size: 12px">AC Actualizado</p>
        <p class="mb-0" style="font-size: 12px; color: #757575" v-if="stage==4">{{stage_date | moment('DD/MM/YYYY')}}</p>
      </v-stepper-step>
    </v-stepper>
  </div>
</template>

<script>
  export default {
    props: ['stage_date','stage_name'],
    data: () => ({
      stage: 0
    }),
    methods:{
      checkCompleteStatus(index){
        if(index <= this.stage){
          return true
        }
        return false
      },
      stepperColor(index){
        if(index <= this.stage){
          return '#E90019'
        }
        return '#2222BF'
      },
      statusColor(index){
        if(index <= this.stage){
          return "color: #2222BF"
        }
        return "color: #A6A6A6"
      },
      setStageIndex(){
        if(this.stage_name == "Stand by Cliente"){
          this.stage = 0
        }
        if(this.stage_name == "Implementacion"){
          this.stage = 1
        }
        if(this.stage_name == "Preparacion (proximo mes)"){
          this.stage = 2
        }
        if(this.stage_name == "Go-Live"){
          this.stage = 3
        }
        if(this.stage_name == 'AC Actualizado'){
          this.stage = 4
        }
      }
    },
    mounted(){
      this.setStageIndex()
    }
  }
</script>

<style lang="scss">
#stepper_vertical {
  .v-stepper--vertical{
    box-shadow: none;
    padding-bottom: 0;

    .v-stepper__content{
      margin: -20px -36px -25px 21px;
      border-left: 2px solid rgba(0, 0, 0, 0.12);
    }

    .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step{
      background: #757575!important
    }

    .v-stepper__step{
      padding: 24px 10px 16px;

      .v-stepper__step__step{
        border: 2px solid white!important;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
        color: transparent;

        .v-icon{
          display: none
        }
      }
    }
  }
}
</style>
