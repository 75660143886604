<template>
  <div>
    <v-card flat class="mt-4 pa-5 pb-8">
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <div class="d-flex">
            <div style="width: 35px">
              <v-img contain height="35px" :src="serviceIcon(info.detail.services)"></v-img>
            </div>
            <div class="ml-3">
              <p style="color: #2222BF; font-size: 16px" class="font-weight-bold mb-0">{{info.detail.services}}</p>
              <p style="color: #757575; font-size: 13px">{{info.detail.cant_employees}} empleados | UF {{info.detail.monthly_fee}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="38" class="mr-2" color="#2222BF">
              <span class="white--text text-uppercase">{{nameInitials(info.detail.pm || '')}}</span>
            </v-avatar>
            <div>
              <p class="mb-0" style="color: #757575; font-size: 13px">Project Manager</p>
              <p class="mb-0 font-weight-medium" style="font-size: 13px">{{info.detail.pm || '-'}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex mt-2 mt-md-0">
            <v-avatar size="38" class="mr-2" color="#2222BF">
              <span class="white--text text-uppercase">{{nameInitials(info.detail.back || '')}}</span>
            </v-avatar>
            <div>
              <p class="mb-0" style="color: #757575; font-size: 13px">Consultor Back</p>
              <p class="mb-0 font-weight-medium" style="font-size: 13px">{{info.detail.back || '-'}}</p>
            </div>
          </div>
        </v-col>
      </v-row>
      <status-steppers :stage_name="info.detail.stage_name" :stage_date="info.detail.stage_date" class="hidden-sm-and-down"></status-steppers>
      <status-steppers-vertical :stage_name="info.detail.stage_name" :stage_date="info.detail.stage_date" class="hidden-md-and-up"></status-steppers-vertical>
      <p style="font-size: 12px; color: #757575;" class="mb-1 mt-3">Datos de contacto</p>
      <p class="mb-0 hidden-sm-and-down" style="font-size: 13px">
        <span class="font-weight-bold">{{info.detail.person_name || 'Información no disponible'}}</span>
        <span style="color: #2222BF" class="ml-4 text-decoration-underline">{{info.detail.person_email || 'E-mail no disponible'}}</span>
        <span class="ml-4">Tel: {{info.detail.person_phone || 'no disponible'}}</span>
      </p>
      <div class="hidden-md-and-up">
        <p style="font-size: 13px" class="font-weight-bold mb-0">{{info.detail.person_name || 'Información no disponible'}}</p>
        <p style="font-size: 13px; color: #2222BF" class="mb-0 text-decoration-underline">{{info.detail.person_email || 'E-mail no disponible'}}</p>
        <p style="font-size: 13px" class="mb-0">Tel: {{info.detail.person_phone || 'no disponible'}}</p>
      </div>
    </v-card>
  </div>
</template>

<script>
import StatusSteppers from './StatusSteppers'
import StatusSteppersVertical from './StatusSteppersVertical'

export default {
  props: ['info'],
  data: () => ({
    publicPath: location.origin
  }),
  components:{
    StatusSteppers,
    StatusSteppersVertical
  },
  methods: {
    serviceIcon(name){
      let icon = ''
      if(name.toLowerCase().includes("asistencia")){ icon = 'asistencia_turnos.png'}
      if(name.toLowerCase().includes("comunicaciones")){ icon = 'comunicaciones.png'}
      if(name.toLowerCase().includes("gestión")){ icon = 'gestion_personas.png'}
      if(name.toLowerCase().includes("firma digital")){icon = 'firma_digital.png'}
      if(name.toLowerCase().includes("remuneraciones")){icon = 'remuneraciones.png'}
      if(name.toLowerCase().includes("desarrollo organizacional")){icon = 'desarrollo_organizacional.png'}
      return `${this.publicPath}/img/services_icons/${icon}`
    },
    statusColor(status){
      if(status == 'Terminada' || status == 'En proceso'){return '#00AF7D'}
      if(status == 'Detenido'){ return '#FF6D00'}
      if(status == 'Pendiente'){ return '#E1E1E1'}
    },
    nameInitials(name){
      let nameArr = name.split(" ")
      return nameArr[1] ? nameArr[0][0]+nameArr[1][0] : nameArr[0][0]
    }
  }
}
</script>
